<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/user-success-stories" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Add Testimonial</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addDistrict">

                            <label>Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" required
                                v-model="story.name" />

                            <label>Designation <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" required
                                v-model="story.designation" />

                            <label>Review <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" required
                             v-model="story.review" @keypress="isReviewNumber($event)" placeholder="Your review must be between 1 and 5" maxlength="1" />

                            <label>Description <span class="text-danger">*</span></label>
                            <textarea type="text" class="form-control form-control-sm mb-3" rows="5" required
                                v-model="story.description"></textarea>


                            <div class="row">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import isReviewNumber from "@/helpers/helpers";

export default {
    mixins: [isReviewNumber],
    data() {
        return {
            story: {
                name: "",
                designation: "",
                review: "",
                description: "",
            },
            loading: false,
        };
    },
    methods: {
        submit() {
            this.loading = true;
            const data = toRaw(this.story);
            this.$store
                .dispatch("stories/store", { data })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly added", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                        Object.keys(this.story).forEach((key) => (this.story[key] = ""));
                    } else {
                        this.$toast.show("Failed to save", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
        fetchAllProvince() {
            this.loading = true;
            this.$store
                .dispatch("province/getAll")
                .then((response) => {
                    this.provinces = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        countryChanged() {
            this.department.province_id = "";
            const id = this.department.country_id;
            this.loading = true;
            this.$store
                .dispatch("province/getAllProvincesByCountry", { id })
                .then((response) => {
                    this.provinces = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
        provinceChanged() {
            this.tehsil.district_id = "";
            const id = this.tehsil.province_id;
            this.loading = true;
            this.$store
                .dispatch("districts/getAllDistrictsByProvince", { id })
                .then((response) => {
                    this.districts = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
      

    },
};
</script>