import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    getAll({ dispatch, commit }, query) {
      return new Promise((resolve, reject) => {
        const queryString = query
          ? `?${Object.entries(query)
              .map(([key, value]) => `${key}=${value}`)
              .join("&")}`
          : "";
        axiosInstance
          .get(`/complaint${queryString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getSingle({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/complaint/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    store({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/complaint", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    update({ dispatch, commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .put(`/complaint/${id}`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    delete({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .delete(`/complaint/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    getAdmins({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/get-complaint-admins/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    storeComplaintStatus({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/store-complaint-admin-id", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    showRoleForDC({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/show-role-for-dc", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    complaintTracking({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/complaint-tracking/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    getDepartments({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/get-departments-district`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    getDepartmentHead({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/get-department-head/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    getDepartmentFocalPersons({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/get-department-focal-perons`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    storeCompleteStatus({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/complete-status", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    UpdateComplaintStatus({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/update-status", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    addRefNo({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/add-reference-no/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    complaintTypes({ dispatch, commit }, query) {
      return new Promise((resolve, reject) => {
        const queryString = query
        ? `?${Object.entries(query)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`
        : "";
        const type = query.type;
        axiosInstance
          .get(`/complaint-types/${type}${queryString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    districtEo({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/get-district-eo`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    storeEO({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/add-eo-on-compliant", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    getAllEnforcementComplaints({ dispatch, commit },query) {
      return new Promise((resolve, reject) => {
        const queryString = query
        ? `?${Object.entries(query)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`
        : "";
        axiosInstance
          .get(`/get-eo-on-compliants${queryString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    storeComment({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/store-comment", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    eOComments({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/comments/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    closeEoComplaint({ dispatch, commit }, { id, data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .put(`/close-eo-complaint/${id}`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    storeCommentTracking({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/store-comment-tracking", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    revertComplaint({ dispatch, commit }, { id, data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .put(`/revert-complaint/${id}`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    searchComplaints({ dispatch, commit }, query) {
      return new Promise((resolve, reject) => {
        const queryString = query
          ? `?${Object.entries(query)
              .map(([key, value]) => `${key}=${value}`)
              .join("&")}`
          : "";
        axiosInstance
          .get(`/search-complaints${queryString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },

    cancelAppealComplaint({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/cancel-appeal-complaint", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    reopoeAppealComplaint({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/reopen-appeal-complaint", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
  },
};
