<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="card">
          <div class="card-header card-no-border">
            <h5 class="card-title">Enforcement Complaints</h5>
          </div>
          <div class="card-body">
            <data-table :columns="columns" :eoComplaintDetail="eoComplaintDetail" :module="module" :items="items"
              :ischat="true" :isClose="isClose" :pagination="pagination" @closeEoComplaint="closeEoComplaint"
              :searchBar="searchBar" :isChangePassword="changePassword" @deleted="fetch" @search="fetch" @clear="fetch"
              @nextPage="nextPage"></data-table>
            <div v-if="closeEO" class="modal-mask" @click="closeEoModel">
              <div class="modal-wrapper" @click.stop>
                <div class="modal-container scrollable">
                  <div class="modal-header mt-3" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                    Close Complaint
                  </div>
                  <div class="modal-body">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-8 offset-md-2">
                          <form @submit.prevent="submitCloseEoComplaint">
                            <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Comment</label>

                            <div class="d-flex align-items-center mb-3" style="
                            border-bottom: 2px solid #ff432a;
                            padding-bottom: 5px;
                          ">
                              <textarea class="form-control form-control-sm border-0" required placeholder="Type Here"
                                v-model="comment" style="
                              border: none;
                              outline: none;
                              box-shadow: none;
                              height: 1.2rem;"></textarea>
                            </div>
                            <div>
                              <div>
                                <label class="mt-2">Attachments</label>
                              </div>
                              <input ref="fileInputComments" @change="uploadAttachment" type="file"
                                accept="image/*,video/*" multiple class="form-control" />
                            </div>
                            <div v-if="filesBase64.length > 0" class="mt-3">
                              <h5>File Previews:</h5>
                              <div v-for="(file, index) in filesBase64" :key="index" class="mb-2">
                                <a href="#" @click.prevent="openFilesComment(file)">{{ file.name }}</a>
                              </div>
                            </div>
                            <div class="row mt-5">
                              <div class="col-md-8">
                                <button type="submit" class="btn btn-lg btn-block w-100 rounded"
                                  style="background-color: #0468B1; color: white">
                                  Submit
                                </button>
                              </div>
                              <div class="col-md-3 mt-3 mt-md-0">
                                <button type="button" @click="closeEoModel"
                                  class="btn btn-outline-primary btn-lg btn-block rounded">
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      searchValue: "",
      viewable: true,
      closeEO: false,
      columns: [
        // {
        //   text: "Complainant Email",
        //   value: "complaint",
        //   secondValue: "user",
        //   thirdValue: "email",
        // },
        {
          text: "Title",
          value: "complaint",
          secondValue: "title",

        },
        {
          text: "Status",
          value: "complaint",
          secondValue: "status",
          thirdValue: "name",

        },
        {
          text: "Date",
          value: "assign_at",
        },
        {
          text: "Reference No.",
          value: "complaint",
          secondValue: "referenceNo",
        },
      ],
      items: [],
      comment: "",
      filesBase64: [],
      module: "complaint",
      ischat: true,
      loading: false,
      isClose: true,
      searchBar: true,
      pagination: true,
      eoComplaintDetail: true,
      id: "",
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch(search = "") {
      this.loading = true;
      this.$store
        .dispatch("complaint/getAllEnforcementComplaints", { search })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    nextPage(url, search = "") {
      this.loading = true;
      axiosInstance.get(`${url}&paginate=true&search=${search}`).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
    closeEoComplaint(id) {
      this.closeEO = true;
      this.id = id;
    },
    submitCloseEoComplaint() {
      this.loading = true;
      const data = {
        comment: this.comment,
        files: this.filesBase64,
      };
      const id = this.id;
      this.$store
        .dispatch("complaint/closeEoComplaint", { id, data })
        .then((response) => {
          this.filesBase64 = [];
          this.comment = "";
          this.closeEO = false;
          this.fetch();
          this.$toast.show("Successfuly Closed", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });

    },
    closeEoModel() {
      this.closeEO = false;
    },
    uploadAttachment(event) {
      const files = event.target.files;
      this.filesBase64 = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          let base64String = e.target.result;
          const mimeType = file.type;
          let type;
          if (mimeType.includes('image')) {
            type = 'Image';
          } else if (mimeType.includes('video')) {
            type = 'Video';
          } else if (mimeType.includes('pdf')) {
            type = 'Pdf';
          } else {
            type = 'Unknown';
          }
          const base64Data = `data:${mimeType};base64,${base64String.split(',')[1]}`;
          this.filesBase64.push({ url: base64Data, name: file.name, type: type });
        };
        reader.readAsDataURL(file);
      }
    },
    openFilesComment(file) {
      const win = window.open();
      if (file.type === 'Image') {
        win.document.write('<img src="' + file.url + '" alt="' + file.name + '">');
      } else if (file.type === 'Video') {
        win.document.write('<video width="500" height="400" controls><source src="' + file.url + '" type="video/mp4"></video>');
      } else if (file.type === 'Pdf') {
        window.open(file.url, '_blank');
      }
    },
  },
};
</script>

<style scoped>
.nav-link.active {
  padding: 15px 80px !important;
  border: 0 !important;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.nav-link {
  background-color: #ddd;
  margin-right: 10px;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  padding: 15px 80px !important;
}

.tab-pane {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.comment-widgets .comment-row:hover {
  background: rgba(0, 0, 0, 0.02);
  cursor: pointer;
}

.comment-widgets .comment-row {
  padding: 15px;
}

.comment-text:hover {
  visibility: hidden;
}

.comment-text:hover {
  visibility: visible;
}

.label {
  padding: 3px 10px;
  line-height: 13px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  font-size: 75%;
}

.round img {
  border-radius: 100%;
}

.label-info {
  background-color: #1976d2;
}

.label-success {
  background-color: green;
}

.label-danger {
  background-color: #ef5350;
}

.action-icons a {
  padding-left: 7px;
  vertical-align: middle;
  color: #99abb4;
}

.action-icons a:hover {
  color: #1976d2;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper {
  width: 40%;
}

.modal-container {
  max-height: 80%;
  overflow-y: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.modal-header,
.modal-footer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.modal-body {
  padding: 20px;
}

.modal-default-button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.select-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 2px solid #0468B1;
  padding-bottom: 5px;
}

.select-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 2px solid #0468B1;
  padding-bottom: 5px;
}


.d-flex.align-items-center.mb-3 {
  border-bottom: 2px solid #0468B1 !important;
}

.select-field {
  flex: 1;
  border: none;
  outline: none;
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  background: transparent;
}

.select-icon {
  color: #0468B1;
  cursor: pointer;
}

.form-control:disabled {
  background-color: #ffffff !important;
}

.rounded {
  border-radius: 20px !important;
}

.btn-outline-primary:hover {
  background-color: #0468B1 !important;
  color: white !important;
}

.scrollable {
  overflow-y: auto;
  max-height: 95vh;
}

.scrollable::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #0468B1;
  border-radius: 10px;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #034074;
}
</style>
