<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="card">
          <div class="card-header">

            <div class="row">
              <div class="col-6">
                <h5 class="card-title">All Notifications</h5>
              </div>
              <div class="col-6 text-end">
                <router-link to="/notification/add" class="btn btn-sm" v-if="permissions && permissions.includes('notification-Send')"
                  style="background-color: rgb(255, 67, 42); color:white;">Send Notification</router-link>
              </div>
            </div>
          </div>
          <div class="card-body">
            <data-table :columns="columns" :module="module" :pagination="pagination" :searchBar="searchBar"
              :items="items" @deleted="fetch" @search="fetch" @clear="fetch" @nextPage="nextPage"></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      items: [
        { name: 'HelpCenter', batch: 'All Users' },

      ],
      columns: [
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Body",
          value: "body",
        },
        {
          text: "Date",
          value: "formattedDate",
        },
        {
          text: "Send To",
          value: "to",
        },

      ],
      loading: false,
      searchBar: true,
      pagination: true,
      items: [],
      permissions:[],
    };
  },
  mounted() {
    this.fetchNotifications();
    this.permissions = localStorage.getItem("permissions");
  },
  methods: {
    fetchNotifications(search = "") {
      const id = localStorage.getItem("uid");
      this.$store
        .dispatch("notification/getAllSendNotifications", { search, id })
        .then((response) => {
          this.items = response.data;
          this.items.data.forEach(item => {
            const date = new Date(item.created_at);
            const options = { day: 'numeric', month: 'short' };
            item.formattedDate = date.toLocaleDateString('en-GB', options);
            let parsedBody = JSON.parse(item.body);
            item.title = parsedBody.title;
            item.body = parsedBody.body;
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    nextPage(url, search = "") {
      this.loading = true;
      axiosInstance.get(`${url}&paginate=true&search=${search}`).then((response) => {
        this.items = response.data.data;
        this.items.data.forEach(item => {
          const date = new Date(item.created_at);
          const options = { day: 'numeric', month: 'short' };
          item.formattedDate = date.toLocaleDateString('en-GB', options);
          let parsedBody = JSON.parse(item.body);
          item.title = parsedBody.title;
          item.body = parsedBody.body;
        });
        this.loading = false;
      });
    },
  }
};
</script>