
<template>
<section class="vh-100">
  <loading :active="loading" :is-full-page="true" loader="bars"></loading>
  <div class="container-fluid h-custom">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-md-9 col-lg-6 col-xl-4">
        <img src="../assets/images/logo/KP_logo.png"
          style="max-width: 80%; height: auto;" alt="Sample image">
      </div>
      <div class="col-md-8 col-lg-6 col-xl-4">
        <form>
          <div class="login-card">
            <div>
              <div></div>
              <div class="login-main">
                <div>
                  <a class="logo">
                    <img
                      class="img-fluid for-light"
                      src="@/assets/images/logo/logo-green.png"
                      alt="looginpage"
                    />
                  </a>
                </div>
                <form class="theme-form" @submit.prevent="login">
                  <h4
                    class="text-center"
                    style="
                      color: #0468b1;
                      font-family: sans-serif;
                      font-weight: 700;
                      font-size: 30px;
                      line-height: 45px;
                    "
                  >
                    Login
                  </h4>
                  <div class="alert alert-danger" v-if="errors.login">
                    <span class="d-block">{{ errors.login }}</span>
                  </div>

                  <div class="alert alert-danger" v-if="errors.text">
                    <b>{{ errors.text }}</b>
                    <div v-if="errors.reason">
                      <span><b>Reason:</b> {{ errors.reason }}</span>
                    </div>
                  </div>
                  <div class="form-group">
                    <label
                      class="col-form-label"
                      style="
                        font-family: sans-serif;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                      "
                      >Email</label
                    >
                    <div
                      class="d-flex align-items-center mb-3"
                      style="
                        border-bottom: 2px solid #0468b1;
                        padding-bottom: 5px;
                      "
                    >
                      <i class="fa fa-envelope" style="margin-right: 10px"></i>
                      <input
                        class="form-control form-control-sm border-0"
                        type="email"
                        placeholder="Enter your email address"
                        v-model="user.email"
                        required
                        autofocus
                        style="
                          margin-left: 10px;
                          border: none;
                          outline: none;
                          box-shadow: none;
                        "
                      />
                    </div>
                    <span class="text-danger" v-if="errors.email">{{
                      errors.email[0]
                    }}</span>
                  </div>
                  <div class="form-group">
                    <label
                      class="col-form-label"
                      style="
                        font-family: sans-serif;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                      "
                      >Password</label
                    >
                    <div
                      class="d-flex align-items-center mb-3"
                      style="
                        border-bottom: 2px solid #0468b1;
                        padding-bottom: 5px;
                      "
                    >
                      <i class="fa fa-key" style="margin-right: 10px"></i>
                      <input
                        class="form-control form-control-sm border-0"
                        type="password"
                        placeholder="Enter password"
                        v-model="user.password"
                        required
                        style="
                          margin-left: 10px;
                          border: none;
                          outline: none;
                          box-shadow: none;
                        "
                      />
                    </div>
                    <span class="text-danger" v-if="errors.password">{{
                      errors.password[0]
                    }}</span>
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <input
                        id="checkbox1"
                        type="checkbox"
                        value="true"
                        v-model="user.remember_me"
                      />
                      <label class="text-muted" for="checkbox1"
                        >Remember me</label
                      >
                    </div>
                    <router-link
                      class="link"
                      style="
                        color: #0468b1;
                        font-family: sans-serif;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                      "
                      to="/auth/verifyEmail"
                      >Forgot password?</router-link
                    >

                    <div class="text-end mt-3">
                      <button
                        class="btn btn-pill btn-block w-100"
                        type="submit"
                        style="
                          background-color: #0468b1;
                          color: #fff;
                          height: 45px;
                        "
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  
</section>
  
</template>
  


<script>
import { toRaw } from "vue";
import router from "@/router";

export default {
  name: "login",
  data() {
    return {
      user: {
        email: "",
        password: "",
        remember_me: false,
      },
      errors: [],
      loading: false,
      permissions: [],
    };
  },
  created() {
    if (!!localStorage.getItem("token")) {
      router.push({ path: "/" });
    }
  },
  methods: {
    login() {
      this.loading = true;
      this.errors = [];
      const data = toRaw(this.user);
      this.$store
        .dispatch("auth/login", { data })
        .then((response) => {
          if (response.success == true) {
            response.data.roles[0].permissions.forEach((item) => {
              this.permissions.push(item.name);
            });
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("sector", response.data.sector);
            localStorage.setItem("uid", response.data.id);
            localStorage.setItem("f_name", response.data.f_name);
            localStorage.setItem("encrypted_id", response.data.encrypted_id);
            localStorage.setItem(
              "role",
              response?.data?.roles?.[0]?.name ?? ""
            );
            localStorage.setItem(
              "profile_image",
              response?.data?.profile_image ?? ""
            );
            localStorage.setItem("permissions", toRaw(this.permissions));
            localStorage.setItem(
              "remember_me",
              response.data.remember_me_token
            );
            this.loading = false;
            window.location.href = "/dashboard";
          } else if (response.success == false) {
            this.errors.text = response.error.text;
            this.errors.reason = response.error.reason;
            this.loading = false;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });
    },
  },
};
</script>

<style scoped>
.checkbox label::before {
  border-color: #0468b1 !important;
}

.logo-1
{
  width: 297px;
  height: 157px
}

.logo-2
{
  width: 150px;
  height: 150px;
}
</style>
