<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <!-- <router-link to="/notification" class="btn btn-sm btn-primary mb-3">Back</router-link> -->
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Send Notification</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit">
                            <div class="mb-2 col-md-12">
                                <label>Select Type <span class="text-danger">*</span></label>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" v-model="notification.type"
                                                    name="typecheck" value="admins" id="adminsRadio" required>
                                                <label class="form-check-label" for="adminsRadio">Administrative
                                                    Officers</label>
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" v-model="notification.type"
                                                    name="typecheck" value="users" id="usersRadio" required>
                                                <label class="form-check-label" for="usersRadio">Users</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-2 col-md-12" v-if="notification.type == 'admins'">
                                <label>Send To <span class="text-danger">*</span></label>
                                <div class="form-group">
                                    <div class="d-flex flex-wrap">
                                        <div class="p-2">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    v-model="notification.recipient" value="DC" id="dcRadio">
                                                <label class="form-check-label" for="dcRadio">DC</label>
                                            </div>
                                        </div>
                                        <div class="p-2">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    v-model="notification.recipient" value="inquiry-officer"
                                                    id="ioRadio">
                                                <label class="form-check-label" for="ioRadio">IO</label>
                                            </div>
                                        </div>
                                        <div class="p-2">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    v-model="notification.recipient" value="Registrar"
                                                    id="registrarRadio">
                                                <label class="form-check-label" for="registrarRadio">Registrar</label>
                                            </div>
                                        </div>
                                        <div class="p-2">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    v-model="notification.recipient" value="Department-Head"
                                                    id="dhRadio">
                                                <label class="form-check-label" for="dhRadio">DH</label>
                                            </div>
                                        </div>
                                        <div class="p-2">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    v-model="notification.recipient" value="Department-FocalPerson"
                                                    id="dfRadio">
                                                <label class="form-check-label" for="dfRadio">DF</label>
                                            </div>
                                        </div>
                                        <div class="p-2">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    v-model="notification.recipient" value="Department-Unit"
                                                    id="duRadio">
                                                <label class="form-check-label" for="duRadio">DU</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <label>Notification Heading <span class="text-danger">*</span></label>
                            <input type="text" v-model="notification.title" class="form-control form-control-sm mb-3"
                                required />

                            <label>Description <span class="text-danger">*</span></label>
                            <textarea v-model="notification.body" class="form-control form-control-sm mb-3" rows="6"
                                required></textarea>

                            <div class="row">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";

export default {
    data() {
        return {
            loading: false,
            admins: [],
            notification: {
                title: "",
                body: "",
                recipient: "",
                to: "",
                type: ""
            }
        };
    },
    mounted() {
        this.fetchAdmins();
    },
    methods: {
        fetchAdmins() {
            this.loading = true;
            const id = localStorage.getItem("uid");
            this.$store
                .dispatch("admins/getAll", { id })
                .then((response) => {
                    this.admins = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e.error);
                    this.loading = false;
                });
        },

        submit() {
            this.loading = true;
            const data = toRaw(this.notification);
            this.notification.to = (this.notification.type === 'admins') ? this.notification.recipient : 'Users';
            this.$store
                .dispatch("notification/store", { data })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly added", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                        Object.keys(this.notification).forEach((key) => (this.notification[key] = ""));
                    }
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
    },
};
</script>