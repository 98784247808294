<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Profile</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="editUser">
              <div class="form-group row">
                <div class="col-md-6">
                  <label>First Name <span class="text-danger">*</span></label>
                  <span class="text-danger d-block" v-if="errors.f_name">
                    First Name is required
                  </span>
                  <input type="text" class="form-control form-control-sm mb-3" required v-model="user.f_name" />
                </div>
                <div class="col-md-6">
                  <label>Last Name</label>
                  <input type="text" class="form-control form-control-sm mb-3" v-model="user.l_name" />
                </div>
              </div>
              <div class="form-group">
                <label>Phone</label>
                <input type="text" class="form-control form-control-sm mb-3" v-model="user.phone"
                  @keypress="isNumber($event)" />
              </div>
              <div class="form-group">
                <label>Email <span class="text-danger">*</span></label>
                <span class="text-danger d-block" v-if="errors.email">
                  {{ errors.email && errors.email.length > 0 ? errors.email[0] : '' }}
                </span>
                <input type="email" class="form-control form-control-sm mb-3" required v-model="user.email" />
              </div>
              <div class="form-group row mt-3">
                <div class="col-md-4">
                  <img :src="previewProfilePic"
                    style="width: 100%; max-width: 120px; height: 120px; border-radius: 50%; object-fit: cover; border: 1px solid #ddd;" />
                </div>
                <div class="col-md-8 mt-3">
                  <label>Upload Profile Picture</label>
                  <div class="position-relative">
                    <input type="file" ref="profilePic" class="position-relative"
                      accept="image/png, image/jpg, image/jpeg" @change="profilePicSelected"
                      style="opacity: 0; z-index: 100" />
                    <button type="button" class="btn btn-primary primary-bg-color position-absolute" style="left: 0">
                      Browse...
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12 mt-3">
                  <button type="submit" class="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import router from "@/router";
import isNumber from "@/helpers/helpers";

export default {
  mixins: [isNumber],
  data() {
    return {
      user: {
        f_Name: null,
        l_name: null,
        phone: null,
        email: null,
        profileImage: null
      },
      roles: [],
      errors: [],
      userId: null,
      loading: false,
      previewProfilePic: "",
    };
  },
  mounted() {
    this.userId = localStorage.getItem("encrypted_id");
    this.fetch(this.userId);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("admins/getSingle", { id })
        .then((response) => {
          this.user = response.data;
          this.previewProfilePic = this.user.profile.image_url;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    submit() {
      this.loading = true;
      this.errors = [];
      const data = toRaw(this.user);
      const id = this.userId;
      this.$store
        .dispatch("admins/update", { data, id })
        .then((response) => {
          localStorage.setItem("profile_image", response?.data ?? "");
          localStorage.setItem("f_name", this.user.f_name);
          this.loading = false;
          this.$toast.show("Successfuly updated.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.errors = [];
          window.location.reload();
        })
        .catch((e) => {
          this.errors = e.error;
          this.loading = false;
        });
    },
    profilePicSelected(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.user.profileImage = e.target.result;
          this.previewProfilePic = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>
