<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-8 mt-5 mx-auto">
                <router-link to="/dynamic-form" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Add Form</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addDistrict">
                            <label for="">Enter Form Title</label>
                            <input type="text" class="form-control form-control-sm" v-model="title" required />
                            <div class="mt-3" v-for="(question, index) in allQuestions" :key="index">
                                <label class="me-3">Question {{ index + 1 }} <span class="text-danger">*</span></label>
                                <input type="text" class="form-control form-control-sm" v-model="question.question"
                                    required />
                                <div class="row mt-3">
                                    <strong>Option Type</strong>
                                    <div class="col-auto mt-2">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" :id="'option1-' + index"
                                                value="radio" :name="'optionQuestion' + index" v-model="question.type"
                                                required>
                                            <label class="form-check-label" :for="'option1-' + index">Radio</label>
                                        </div>
                                    </div>
                                    <div class="col-auto mt-2">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" :id="'option2-' + index"
                                                value="text" :name="'optionQuestion' + index" v-model="question.type"
                                                required>
                                            <label class="form-check-label" :for="'option2-' + index">Text</label>
                                        </div>
                                    </div>
                                    <div class="col-auto mt-2">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" :id="'option3-' + index"
                                                value="checkbox" :name="'optionQuestion' + index"
                                                v-model="question.type" required>
                                            <label class="form-check-label" :for="'option3-' + index">Checkbox</label>
                                        </div>
                                    </div>
                                    <div class="col-auto mt-2">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" :id="'option4-' + index"
                                                value="numeric" :name="'optionQuestion' + index" v-model="question.type"
                                                required>
                                            <label class="form-check-label" :for="'option4-' + index">Numeric</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3" v-if="question.type == 'radio' || question.type == 'checkbox'">
                                    <div class="row">
                                        <div class="col-md-6" v-for="(option, oIndex) in question.options"
                                            :key="oIndex">
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <strong>Option {{ oIndex + 1 }}</strong>
                                                <div @click="removeOption(question, oIndex)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        fill="currentColor" class="bi bi-x-circle-fill"
                                                        viewBox="0 0 16 16" style="color: #ff432a;">
                                                        <path fill-rule="evenodd"
                                                            d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zM4.354 4.354a.5.5 0 0 1 .708 0L8 7.293l3.938-3.939a.5.5 0 0 1 .708.708L8.707 8l3.939 3.938a.5.5 0 1 1-.708.708L8 8.707l-3.938 3.939a.5.5 0 1 1-.708-.708L7.293 8 3.354 4.354a.5.5 0 0 1 0-.708z" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <input type="text" v-model="option.option" class="form-control"
                                                placeholder="Option" required>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex">
                                    <button v-if="question.type == 'radio' || question.type == 'checkbox'" type="button"
                                        class="btn btn-sm btn-primary mt-3 me-3" @click="addOptions(question)">Add
                                        Option</button>
                                </div>
                                <div class="mt-3">
                                    <input type="checkbox" class="me-1" v-model="question.isRequired"
                                        :id="'requiredInput-' + index" />
                                    <label :for="'requiredInput-' + index">Required</label>
                                </div>

                                <div class="mt-2" v-if="allQuestions && allQuestions.length > 1">
                                    <hr>
                                </div>

                            </div>
                            <button class="btn btn-sm btn-primary mt-5" @click="addQuestion">Add Question</button>
                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { toRaw } from "vue";

export default {
    data() {
        return {
            allQuestions: [],
            title: "",
            loading: false,
        };
    },
    created() {

    },

    methods: {
        addQuestion(e) {
            e.preventDefault();
            this.allQuestions.push({
                question: "",
                type: "",
                isRequired: 0,
                options: []
            });
        },
        addOptions(question) {
            if (question.options.length < 4) {
                question.options.push({ option: "" });
            } else {
                alert("Maximum of 4 options reached");
            }
        },
        removeOption(question, index) {
            question.options.splice(index, 1);
        },
        submit() {
            const formData = {
                questions: this.allQuestions,
                title: this.title
            };
            this.loading = true;
            const data = toRaw(formData);
            this.$store
                .dispatch("form/store", { data })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly added", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });

                        this.title = "";
                        this.allQuestions = [];

                    } else {
                        this.$toast.show("Failed to save", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },


    },
};
</script>