export var menuItems = {
  data: [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: "stroke-home",
      iconf: "fill-home",
      type: "link",
      active: false,
      module: "dashboard",
      public: true,
    },
    {
      title: "Administration",
      icon: "stroke-user",
      iconf: "fill-user",
      type: "sub",
      active: false,
      module: ["admin-View", "role-View"],
      children: [
        {
          path: "/admins",
          title: "Admins",
          type: "link",
          active: false,
          module: "admin-View",
        },
        {
          path: "/roles",
          title: "Roles",
          type: "link",
          active: false,
          module: "role-View",
        },
      ],
    },
    // {
    //   title: "Region Control",
    //   icon: "stroke-internationalization",
    //   iconf: "fill-internationalization",
    //   type: "sub",
    //   active: false,
    //   module: ["country-View","province-View"],
    //   children: [
    //     {
    //       path: "/country",
    //       title: "Country",
    //       type: "link",
    //       active: false,
    //       module: "country-View",
    //     },
    //     {
    //       path: "/province",
    //       title: "Province",
    //       type: "link",
    //       active: false,
    //       module: "province-View",
    //     },
    //   ],
    // },

    // {
    //   title: "Administrative Regions",
    //   icon: "stroke-internationalization",
    //   iconf: "fill-internationalization",
    //   type: "sub",
    //   active: false,
    //   module: ["districtOfficer-View"],
    //   children: [

    //     {
    //       path: "/district-officers",
    //       title: "District Officers",
    //       type: "link",
    //       active: false,
    //       module: "districtOfficer-View",

    //     },
    //   ],
    // },

    {
      title: "District Officers",
      path: "/district-officers",
      icon: "stroke-internationalization",
      iconf: "fill-internationalization",
      type: "link",
      active: false,
      module: "districtOfficer-View",
    },

    {
      title: "Department Officers",
      path: "/department-officers",
      icon: "stroke-learning",
      iconf: "fill-learning",
      type: "link",
      active: false,
      module: "departmentOfficers-View",
    },

    {
      title: "All Complaints",
      path: "/complaints",
      icon: "stroke-knowledgebase",
      iconf: "fill-knowledgebase",
      type: "link",
      active: false,
      module: "complaint-View",
    },
    {
      title: "Active Complaints",
      path: "/active-complaints",
      icon: "stroke-knowledgebase",
      iconf: "fill-knowledgebase",
      type: "link",
      active: false,
      module: "activeComplaint",
    },
    {
      title: "CMC Complaints",
      path: "/cmc-complaints",
      icon: "stroke-knowledgebase",
      iconf: "fill-knowledgebase",
      type: "link",
      active: false,
      module: "cmc-complaints",
    },

    {
      title: "Closed Complaints",
      path: "/closed-complaints",
      icon: "stroke-knowledgebase",
      iconf: "fill-knowledgebase",
      type: "link",
      active: false,
      module: "closedComplaint",
    },
    {
      title: "Appeal Complaints",
      path: "/reappeal-complaints",
      icon: "stroke-knowledgebase",
      iconf: "fill-knowledgebase",
      type: "link",
      active: false,
      module: "reAppealComplaint",
    },
    {
      title: "Enforcement Complaints",
      path: "/enforcement-appeals",
      icon: "stroke-knowledgebase",
      iconf: "fill-knowledgebase",
      type: "link",
      active: false,
      module: "enforcementComplaint",
    },
    {
      title: "Manage SOPs",
      path: "/sops",
      icon: "stroke-editors",
      iconf: "fill-editors",
      type: "link",
      active: false,
      module: "sop-View",
    },
    {
      title: "Dynamic Form",
      path: "/dynamic-form",
      icon: "stroke-task",
      iconf: "fill-task",
      type: "link",
      active: false,
      module: "dynamicForm-View",
    },
    {
      title: "Reporting",
      path: "/search-and-reports",
      icon: "stroke-search",
      iconf: "fill-search",
      type: "link",
      active: false,
      module: "reporting",
    },
    {
      title: "Track Performance",
      path: "/track-performance",
      icon: "stroke-charts",
      iconf: "fill-charts",
      type: "link",
      active: false,
      module: "trackPerformance-View",
    },
    {
      title: "App Users",
      path: "/app-users",
      icon: "stroke-user",
      iconf: "fill-user",
      type: "link",
      active: false,
      module: "appUsers-View",
    },
    {
      title: "Notification",
      path: "/notification",
      icon: "notification",
      iconf: "notification",
      type: "link",
      active: false,
      module: "notification-View",
    },

    {
      title: "Setting",
      icon: "stroke-faq",
      iconf: "fill-faq",
      type: "sub",
      active: false,
      module: ["setting"],
      children: [
        // {
        //   path: "/country",
        //   title: "Country",
        //   type: "link",
        //   active: false,
        //   module: ["setting"],
        // },
        // {
        //   path: "/province",
        //   title: "Province",
        //   type: "link",
        //   active: false,
        //   module: ["setting"],
        // },
        {
          path: "/districts",
          title: "District",
          type: "link",
          active: false,
          module: ["setting"],
        },
        {
          path: "/tehsil",
          title: "Tehsil",
          type: "link",
          active: false,
          module: ["setting"],
        },
        {
          path: "/area",
          title: "Area",
          type: "link",
          active: false,
          module: ["setting"],
        },
        {
          path: "/qualification",
          title: "Qualification",
          type: "link",
          active: false,
          module: ["setting"],
        },
        {
          path: "/department",
          title: "Departments",
          type: "link",
          active: false,
          module: ["setting"],
        },
      ],
    },

    {
      title: "Web Content",
      icon: "stroke-blog",
      iconf: "fill-blog",
      type: "sub",
      active: false,
      module: ["webContent"],
      children: [
        {
          path: "/faq",
          title: "FAQS",
          type: "link",
          active: false,
          module: "webContent",
        },
        {
          path: "/how-to",
          title: "How TO",
          type: "link",
          active: false,
          module: "webContent",
        },
        {
          path: "/privacy-policy",
          title: "Privacy Policy",
          type: "link",
          active: false,
          module: "webContent",
        },
        {
          path: "/term-and-condition",
          title: "Term and Condition",
          type: "link",
          active: false,
          module: "webContent",
        },
        {
          path: "/web-pages",
          title: "Web Pages",
          type: "link",
          active: false,
          module: "webContent",
        },
        {
          path: "/news",
          title: "News",
          type: "link",
          active: false,
          module: "webContent",
        },
        {
          path: "/user-success-stories",
          title: "Testimonials",
          type: "link",
          active: false,
          module: "webContent",
        },
      ],
    },
    // {
    //   title: "Feedback",
    //   path: "/feedback",
    //   icon: "stroke-bookmark",
    //   iconf: "fill-bookmark",
    //   type: "link",
    //   active: false,
    //   module: "feedback",
    // },
    {
      title: "Feedback",
      icon: "stroke-bookmark",
      iconf: "fill-bookmark",
      type: "sub",
      active: false,
      module: ["feedback"],
      children: [
        {
          path: "/feedback",
          title: "Feedback",
          type: "link",
          active: false,
          module: "feedback",
        },
        {
          path: "/anonymous-feedback",
          title: "Anonymous Feedback",
          type: "link",
          active: false,
          module: "feedback",
        },
      ],
    },
    {
      title: "Support",
      path: "/support",
      icon: "stroke-support-tickets",
      iconf: "fill-support-tickets",
      type: "link",
      active: false,
      module: "support",
    },
  ],
};
