<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/department" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Add Department</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addDistrict">
                            <div class="form-group">
                                <label>District <span class="text-danger">*</span></label>
                                <select class="custom-select mb-3" v-model="department.district_id" required>
                                    <option value="">Select...</option>
                                    <option v-for="district in districts" :value="district.id">
                                        {{ district.name }}
                                    </option>
                                </select>
                            </div>
                            <label>Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" required
                                v-model="department.name" />
                            <label>Location  <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" required
                                v-model="department.location" />
                            <div class="row">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";

export default {
    data() {
        return {
            department: {
                name: "",
                district_id: "",
                country_id: 1,
                province_id: 1,
                location: ""
            },
            districts: [],
            countries: [],
            provinces: [],
            loading: false,
        };
    },
    mounted() {
        this.fetchAllDistricts();
    },
    methods: {
        submit() {
            this.loading = true;
            const data = toRaw(this.department);
            this.$store
                .dispatch("department/store", { data })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly added", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                        Object.keys(this.department).forEach((key) => (this.department[key] = null));
                        this.department.district_id = "";
                        this.department.country_id = "";
                        this.department.province_id = "";
                        this.department.location = "";
                    } else {
                        this.$toast.show("Failed to save", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
        fetchCountry() {
            this.loading = true;
            this.$store
                .dispatch("country/getAll")
                .then((response) => {
                    this.countries = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        countryChanged() {
            this.department.province_id = "";
            const id = this.department.country_id;
            this.loading = true;
            this.$store
                .dispatch("province/getAllProvincesByCountry", { id })
                .then((response) => {
                    this.provinces = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
        provinceChanged() {
            this.department.district_id = "";
            const id = this.department.province_id;
            this.loading = true;
            this.$store
                .dispatch("districts/getAllDistrictsByProvince", { id })
                .then((response) => {
                    this.districts = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
        fetchAllDistricts() {
            this.loading = true;
            this.$store
                .dispatch("districts/getAllDistricts")
                .then((response) => {
                    this.districts = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        }
    },
};
</script>