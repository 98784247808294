<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mt-5 mx-auto">
        <router-link to="/roles" class="btn btn-sm btn-primary mb-3">Back</router-link>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Edit Role</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addRole">
              <label class="primary-text-color">Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" required v-model="role.name" />
              <div class="row mt-3">
                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="checkAll" @change="toggleCheckAll">
                    <label for="checkAll">Check All</label>
                  </div>
                </div>
              </div>
              <!-- Admin Permissions -->
              <div class="row mt-3">
                <div class="col-12">
                  <!-- Admin Title with Fixed Width -->
                  <div class="me-5" style="font-weight: bold; white-space: nowrap; width: 5rem;">Admin</div>
                  <!-- Permission Checkboxes Wrapper -->
                  <div class="col-12 d-flex flex-wrap mt-1">
                    <!-- View Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-1" v-model="role.permissions"
                        :value="getName('admin', 'View')">
                      <label class="form-check-label" for="addCheckbox-1">View</label>
                    </div>
                    <!-- Add Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-2" v-model="role.permissions"
                        :value="getName('admin', 'Add')">
                      <label class="form-check-label" for="addCheckbox-2">Add</label>
                    </div>
                    <!-- Edit Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-888" v-model="role.permissions"
                        value="admin-Edit">
                      <label class="form-check-label" for="addCheckbox-888">Edit</label>
                    </div>
                    <!-- Block Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-889" v-model="role.permissions"
                        value="adminBlock">
                      <label class="form-check-label" for="addCheckbox-889">Block</label>
                    </div>
                    <!-- Delete Admin Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-875" v-model="role.permissions"
                        value="admin-Delete">
                      <label class="form-check-label" for="addCheckbox-875">Delete</label>
                    </div>
                    <!-- Change Password Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-890" v-model="role.permissions"
                        value="admin-changePassword">
                      <label class="form-check-label" for="addCheckbox-890">Change Password</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Roles Permissions -->
              <div class="row mt-3">
                <div class="col-12">
                  <!-- Roles Title with Same Fixed Width as Admin -->
                  <div class="me-5" style="font-weight: bold; white-space: nowrap; width: 5rem;">Roles</div>
                  <!-- Permission Checkboxes Wrapper -->
                  <div class="col-12 d-flex flex-wrap mt-1">
                    <!-- View Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-6" v-model="role.permissions"
                        :value="getName('role', 'View')">
                      <label class="form-check-label" for="addCheckbox-6">View</label>
                    </div>
                    <!-- Add Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-7" v-model="role.permissions"
                        :value="getName('role', 'Add')">
                      <label class="form-check-label" for="addCheckbox-7">Add</label>
                    </div>
                    <!-- Edit Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="editCheckbox-8" v-model="role.permissions"
                        :value="getName('role', 'Edit')">
                      <label class="form-check-label" for="editCheckbox-8">Edit</label>
                    </div>
                    <!-- Delete Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="deleteCheckbox-9" v-model="role.permissions"
                        :value="getName('role', 'Delete')">
                      <label class="form-check-label" for="deleteCheckbox-9">Delete</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- District Officer Permissions -->
              <div class="row mt-3">
                <div class="col-12">
                  <!-- District Officer Title with Same Fixed Width -->
                  <div class="me-5" style="font-weight: bold; white-space: nowrap; width: 5rem;">Dist. Officer</div>
                  <!-- Permission Checkboxes Wrapper -->
                  <div class="col-12 d-flex flex-wrap mt-1">
                    <!-- View Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-18" v-model="role.permissions"
                        :value="getName('districtOfficer', 'View')">
                      <label class="form-check-label" for="addCheckbox-18">View</label>
                    </div>
                    <!-- Add Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-19" v-model="role.permissions"
                        :value="getName('districtOfficer', 'Add')">
                      <label class="form-check-label" for="addCheckbox-19">Add</label>
                    </div>
                    <!-- Edit Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-3004" v-model="role.permissions"
                        :value="getName('districtOfficer', 'Edit')">
                      <label class="form-check-label" for="addCheckbox-3004">Edit</label>
                    </div>
                    <!-- Block Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-3005" v-model="role.permissions"
                        :value="getName('districtOfficer', 'Block')">
                      <label class="form-check-label" for="addCheckbox-3005">Block</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Department Officers Permissions -->
              <div class="row mt-3">
                <div class="col-12">
                  <!-- Department Officers Title with Same Fixed Width -->
                  <div class="me-5" style="font-weight: bold; white-space: nowrap; width: 5rem;">Dept. Officers
                  </div>
                  <!-- Permission Checkboxes Wrapper -->
                  <div class="col-12 d-flex flex-wrap mt-1">
                    <!-- View Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-26" v-model="role.permissions"
                        :value="getName('departmentOfficers', 'View')">
                      <label class="form-check-label" for="addCheckbox-26">View</label>
                    </div>
                    <!-- Add Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-27" v-model="role.permissions"
                        :value="getName('departmentOfficers', 'Add')">
                      <label class="form-check-label" for="addCheckbox-27">Add</label>
                    </div>
                    <!-- Edit Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-3000" v-model="role.permissions"
                        :value="getName('departmentOfficers', 'Edit')">
                      <label class="form-check-label" for="addCheckbox-3000">Edit</label>
                    </div>
                    <!-- Block Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-3001" v-model="role.permissions"
                        :value="getName('departmentOfficers', 'Block')">
                      <label class="form-check-label" for="addCheckbox-3001">Block</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Complaints Permissions -->
              <div class="row mt-3">
                <div class="col-12">
                  <!-- Complaints Title with Same Fixed Width -->
                  <div class="me-5" style="font-weight: bold; white-space: nowrap; width: 5rem;">Complaints</div>
                  <!-- Permission Checkboxes Wrapper -->
                  <div class="col-12 d-flex flex-wrap mt-1">
                    <!-- View Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-36" v-model="role.permissions"
                        :value="getName('complaint', 'View')">
                      <label class="form-check-label" for="addCheckbox-36">View</label>
                    </div>
                    <!-- Add Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-78963" v-model="role.permissions"
                        :value="getName('complaint', 'Add')">
                      <label class="form-check-label" for="addCheckbox-78963">Add</label>
                    </div>
                    <!-- Edit Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="editCheckbox-12378" v-model="role.permissions"
                        :value="getName('complaint', 'Edit')">
                      <label class="form-check-label" for="editCheckbox-12378">Edit</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Manage SOP Permissions -->
              <div class="row mt-3">
                <div class="col-12">
                  <!-- Manage SOP Title with Same Fixed Width -->
                  <div class="me-5" style="font-weight: bold; white-space: nowrap; width: 5rem;">Manage Sops</div>
                  <!-- Permission Checkboxes Wrapper -->
                  <div class="col-12 d-flex flex-wrap mt-1">
                    <!-- View Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-40" v-model="role.permissions"
                        :value="getName('sop', 'View')">
                      <label class="form-check-label" for="addCheckbox-40">View</label>
                    </div>
                    <!-- Update Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="editCheckbox-42" v-model="role.permissions"
                        value="sop-Update">
                      <label class="form-check-label" for="editCheckbox-42">Update</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Dynamic Form Permissions -->
              <div class="row mt-3">
                <div class="col-12">
                  <!-- Dynamic Form Title with Same Fixed Width -->
                  <div class="me-5" style="font-weight: bold; white-space: nowrap; width: 5rem;">Dynamic Form</div>
                  <!-- Permission Checkboxes Wrapper -->
                  <div class="col-12 d-flex flex-wrap mt-1">
                    <!-- View Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-8888" v-model="role.permissions"
                        value="dynamicForm-View">
                      <label class="form-check-label" for="addCheckbox-8888">View</label>
                    </div>
                    <!-- Add Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-7896" v-model="role.permissions"
                        value="dynamicForm-Add">
                      <label class="form-check-label" for="addCheckbox-7896">Add</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Reporting Permissions -->
              <div class="row mt-3">
                <div class="col-12">
                  <!-- Dynamic Form Title with Same Fixed Width -->
                  <div class="me-5" style="font-weight: bold; white-space: nowrap; width: 5rem;">Reporting</div>
                  <!-- Permission Checkboxes Wrapper -->
                  <div class="col-12 d-flex flex-wrap mt-1">
                    <!-- Reporting Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-8532" v-model="role.permissions"
                        value="reporting">
                      <label class="form-check-label" for="addCheckbox-8532">Access</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <!-- Track Performance Title with Same Fixed Width -->
                  <div class="me-5" style="font-weight: bold; white-space: nowrap; width: 5rem;">Track Performance
                  </div>
                  <!-- Track Performance Permission Checkboxes Wrapper -->
                  <div class="col-12 d-flex flex-wrap mt-1">
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-200" v-model="role.permissions"
                        :value="getName('trackPerformance', 'View')">
                      <label class="form-check-label" for="addCheckbox-200">View</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- App Users Permissions -->
              <div class="row mt-3">
                <div class="col-12">
                  <!-- App Users Title with Same Fixed Width -->
                  <div class="me-5" style="font-weight: bold; white-space: nowrap; width: 5rem;">App Users</div>
                  <!-- Permission Checkboxes Wrapper -->
                  <div class="col-12 d-flex flex-wrap mt-1">
                    <!-- View Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-100" v-model="role.permissions"
                        :value="getName('appUsers', 'View')">
                      <label class="form-check-label" for="addCheckbox-100">View</label>
                    </div>
                    <!-- Add Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-3040" v-model="role.permissions"
                        :value="getName('appUsers', 'Add')">
                      <label class="form-check-label" for="addCheckbox-3040">Add</label>
                    </div>
                    <!-- Edit Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-101" v-model="role.permissions"
                        :value="getName('appUsers', 'Edit')">
                      <label class="form-check-label" for="addCheckbox-101">Edit</label>
                    </div>
                    <!-- Block Permission -->
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-3020" v-model="role.permissions"
                        :value="getName('appUsers', 'Block')">
                      <label class="form-check-label" for="addCheckbox-3020">Block</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <!-- Notification Title with Same Fixed Width -->
                  <div class="me-5" style="font-weight: bold; white-space: nowrap; width: 5rem;">Notification</div>
                  <!-- Notification Permission Checkboxes Wrapper -->
                  <div class="col-12 d-flex flex-wrap mt-1">
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-203" v-model="role.permissions"
                        :value="getName('notification', 'View')">
                      <label class="form-check-label" for="addCheckbox-203">View</label>
                    </div>
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-204" v-model="role.permissions"
                        :value="getName('notification', 'Send')">
                      <label class="form-check-label" for="addCheckbox-204">Send</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <!-- Setting Title with Same Fixed Width -->
                  <div class="me-5" style="font-weight: bold; white-space: nowrap; width: 5rem;">
                    Setting
                    <i class="fa fa-info-circle" data-bs-toggle="tooltip" data-bs-placement="top"
                      title="This setting allows all operations of Country, Province, District, Tehsil, and Tehsil Area modules."></i>
                  </div>
                  <!-- Setting Permission Checkboxes Wrapper -->
                  <div class="col-12 d-flex flex-wrap mt-1">
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-8596" v-model="role.permissions"
                        value="setting">
                      <label class="form-check-label" for="addCheckbox-8596">Access</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <!-- Web Pages Title with Same Fixed Width -->
                  <div class="me-5" style="font-weight: bold; white-space: nowrap; width: 5rem;">Web Pages</div>
                  <!-- Web Pages Permission Checkboxes Wrapper -->
                  <div class="col-12 d-flex flex-wrap mt-1">
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-7000" v-model="role.permissions"
                        value="webContent">
                      <label class="form-check-label" for="addCheckbox-7000">Access</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <!-- Web Pages Title with Same Fixed Width -->
                  <div class="me-5" style="font-weight: bold; white-space: nowrap; width: 5rem;">Feedbacks</div>
                  <!-- Web Pages Permission Checkboxes Wrapper -->
                  <div class="col-12 d-flex flex-wrap mt-1">
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-5263" v-model="role.permissions"
                        value="feedback">
                      <label class="form-check-label" for="addCheckbox-5263">View</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <!-- Web Pages Title with Same Fixed Width -->
                  <div class="me-5" style="font-weight: bold; white-space: nowrap; width: 5rem;">Support</div>
                  <!-- Web Pages Permission Checkboxes Wrapper -->
                  <div class="col-12 d-flex flex-wrap mt-1">
                    <div class="form-check me-5">
                      <input class="form-check-input" type="checkbox" id="addCheckbox-5264" v-model="role.permissions"
                        value="support">
                      <label class="form-check-label" for="addCheckbox-5264">View</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";

export default {
  data() {
    return {
      role: {
        name: "",
        permissions: [],
      },
      roleId: null,
      loading: false,
      modules: [],
    };
  },
  mounted() {
    this.roleId = this.$route.params.id;
    this.fetchModules();
    this.fetch(this.roleId);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("roles/getSingle", { id })
        .then((response) => {
          this.role = response.data;
          console.log(toRaw(this.role));
          this.role.permissions.forEach((item) => {
            this.role.permissions.push(item.name);
          });
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.role);
      data.permissions = data.permissions.filter(
        (permission) => typeof permission === "string"
      );
      const id = this.roleId;
      this.$store
        .dispatch("roles/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.$refs.addRole.reset();
          } else {
            this.$toast.show("Failed to udpate", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    getName(module, action) {
      return `${module}-${action}`;
    },
    toggleCheckAll(event) {
      const checkboxes = document.querySelectorAll('input[type="checkbox"]:not(#checkAll)');
      checkboxes.forEach(checkbox => {
        checkbox.checked = event.target.checked;
        if (checkbox.checked && !this.role.permissions.includes(checkbox.value)) {
          this.role.permissions.push(checkbox.value);
        } else if (!checkbox.checked) {
          const index = this.role.permissions.indexOf(checkbox.value);
          if (index !== -1) {
            this.role.permissions.splice(index, 1);
          }
        }
      });
    },
    fetchModules() {
      this.loading = true;
      this.$store
        .dispatch("module/getAll")
        .then((response) => {
          this.loading = false;
          this.modules = response.data;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
