import axios from "axios";
import router from "@/router";

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}`,
});

if (localStorage.getItem("token") !== null) {
  axiosInstance.interceptors.request.use((request) => {
    const token = "Bearer " + localStorage.getItem("token");
    request.headers = {
      Authorization: token,
      Accept: "application/json",
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    return request;
  });
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (e) => {
      if (e.response.status === 401) {
        localStorage.clear();
        router.push("/auth/login");
      }
      return Promise.reject(e);
    }
  );
}

export default axiosInstance;
