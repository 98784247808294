export default {
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isReviewNumber(event) {
      const charCode = event.keyCode ? event.keyCode : event.which;
      if (charCode < 49 || charCode > 53) {
          event.preventDefault(); 
      }
  }
  },
};
