import { createWebHistory, createRouter } from "vue-router";
import Body from "../components/body";
import Dashboard from "@/views/dashboard/default";
import RoleDashboard from "@/views/dashboard/roleDashboard/index";
import Login from "@/auth/login";
import VerfiyEmail from "@/auth/verify-email.vue";
import verifyCode from "@/auth/verify-code.vue";
import ResetPassword from "@/auth/reset_password.vue";
import RoleIndex from "@/views/role/index";
import AddRole from "@/views/role/add";
import EditRole from "@/views/role/edit";
import AdminIndex from "@/views/admin/index";
import AddAdmin from "@/views/admin/add";
import EditAdmin from "@/views/admin/edit";
import AdminProfile from "@/views/admin/profile";
import AdminChangePassword from "@/views/admin/changePassword";
import DistrictIndex from "@/views/districts/index";
import AddDistrict from "@/views/districts/add";
import EditDistrict from "@/views/districts/edit";
import OfficerIndex from "@/views/officers/index";
import OfficerAdd from "@/views/officers/add";
import OfficerEdit from "@/views/officers/edit";
import EditDepartment from "@/views/department/edit";
import DepartmentIndex from "@/views/department/index";
import DepartmentAdd from "@/views/department/add";
import EditDepartmentOfficer from "@/views/departmentOfficer/edit";
import DepartmentOfficerIndex from "@/views/departmentOfficer/index";
import DepartmentOfficerAdd from "@/views/departmentOfficer/add";
// import EditDepartmentUnit from "@/views/departmentunit/edit";
// import DepartmentUnitIndex from "@/views/departmentunit/index";
// import DepartmentUnitAdd from "@/views/departmentunit/add";
import EditComplaint from "@/views/complaint/edit";
import ComplaintIndex from "@/views/complaint/index";
import ComplaintREAPPEAL from "@/views/complaint/reappeal";
import ComplaintAdd from "@/views/complaint/add";
import SOPSAdd from "@/views/sops/add";
import SOPSINDEX from "@/views/sops/index";
import TrackINDEX from "@/views/trackPerformance/index";
import NotificationINDEX from "@/views/notification/index";
import NotificationAdd from "@/views/notification/add";
import FAQINDEX from "@/views/faq/index";
import FAQAdd from "@/views/faq/add";
import FAQEDIT from "@/views/faq/edit";
import HOWTOINDEX from "@/views/howto/index";
import HOWTOADD from "@/views/howto/add";
import HOWTOEdit from "@/views/howto/edit";

import FEEDBACKINDEX from "@/views/feedback/index";
import AnonymousFeedback from "@/views/feedback/anonymous";
import FEEDBACKEdit from "@/views/feedback/view";
import AnonymousView from "@/views/feedback/anonymousView";

import SupportINDEX from "@/views/support/index";
import SupportView from "@/views/support/view";

import COUNTRYADD from "@/views/regionControl/country/add";
import COUNTRYINDEX from "@/views/regionControl/country/index";
import COUNTRYEDIT from "@/views/regionControl/country/edit";

import PROVINCEADD from "@/views/regionControl/province/add";
import PROVINCEINDEX from "@/views/regionControl/province/index";
import PROVINCEEDIT from "@/views/regionControl/province/edit";

import QUESTIONADD from "@/views/questions/add";

import USERSINDEX from "@/views/users/index";

import USERSAdd from "@/views/users/add";
import USERSEdit from "@/views/users/edit";

import ACTIVECOMPLAINTS from "@/views/dcComplaints/active";
import REAPPEALCOMPLAINTS from "@/views/dcComplaints/reAppeal";
import REENFORCEMENTCOMPLAINTS from "@/views/dcComplaints/reenforcement";
import CHATENFORCEMENTINDEX from "@/views/dcComplaints/chat";
import CLOSEDCOMPLIANTS from "@/views/dcComplaints/closed";

import CONTENTINDEX from "@/views/webContent/index";
import TermAndCondition from "@/views/webContent/termandcondition";
import PriacyPolicyIndex from "@/views/webContent/privacyPolicy";

import RistrictedAccessINDEX from "@/views/ristrictedAccess/index";
import RistrictedAccessAdd from "@/views/ristrictedAccess/add";
import RistrictedAccessEdit from "@/views/ristrictedAccess/edit";
import CMCCOMPLAINTSINDEX from "@/views/ristrictedAccess/complaints";

import DynamicForm from "@/views/dynamicForm/index";
import PerviewForm from "@/views/questions/edit";

import TeshsilAdd from "@/views/tehsil/add";
import TeshsilIndex from "@/views/tehsil/index";
import TeshsilEdit from "@/views/tehsil/edit";

import AreaAdd from "@/views/area/add";
import AreaIndex from "@/views/area/index";
import AreaEdit from "@/views/area/edit";

import QualificationAdd from "@/views/qualification/add";
import QualificationIndex from "@/views/qualification/index";
import QualificationEdit from "@/views/qualification/edit";

import EnforcementCompiantDetail from "@/views/enforcement/detail";

import StoryIndex from "@/views/webContent/stories/index";
import StoryAdd from "@/views/webContent/stories/add";
import StoryEdit from "@/views/webContent/stories/edit";

import NewsIndex from "@/views/webContent/news/index";
import NewsAdd from "@/views/webContent/news/add";
import NewsEdit from "@/views/webContent/news/edit";

import Reporting from "@/views/searchandreport/index";

const roles = [
  "DC",
  "inquiry-officer",
  "Department-Head",
  "Department-FocalPerson",
  "Department-Unit",
];
const userRole = localStorage.getItem("role");
const dashboardComponent = roles.includes(userRole) ? Dashboard : Dashboard;

import Testing from "@/views/dashboard/testing";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    component: Body,
    children: [
      {
        path: "/dashboard",
        component: dashboardComponent,
        name: "Dashboard",
      },
    ],
  },
  {
    path: "/testing",
    component: Body,
    children: [
      {
        path: "/testing",
        component: Testing,
        name: "Testing",
      },
    ],
  },
  {
    path: "/auth",
    children: [
      {
        path: "login",
        name: "Login 1",
        component: Login,
        meta: {
          public: true,
        },
      },
      {
        path: "verifyEmail",
        component: VerfiyEmail,
        meta: {
          public: true,
        },
      },
      {
        path: "verifyCode",
        component: verifyCode,
        meta: {
          public: true,
        },
      },
      {
        path: "resetPassword",
        component: ResetPassword,
        meta: {
          public: true,
        },
      },
    ],
  },
  {
    path: "/roles",
    component: Body,
    children: [
      {
        path: "/roles",
        component: RoleIndex,
        meta: {
          module: "role-View",
        },
      },
      {
        path: "/roles/add",
        component: AddRole,
        meta: {
          module: "role-Add",
        },
      },
      {
        path: "/roles/edit/:id",
        component: EditRole,
        meta: {
          module: "role-Edit",
        },
      },
    ],
  },
  {
    path: "/admins",
    component: Body,
    children: [
      {
        path: "/admins/profile",
        component: AdminProfile,
      },
      {
        path: "/admins/changePassword/:id?",
        component: AdminChangePassword,
        meta: {
          module: "admin-changePassword",
        },
      },
      {
        path: "/admins",
        component: AdminIndex,
        meta: {
          module: "admin-View",
        },
      },
      {
        path: "/admins/add",
        component: AddAdmin,
        meta: {
          module: "admin-Add",
        },
      },
      {
        path: "/admins/edit/:id",
        component: EditAdmin,
        meta: {
          module: "admin-Edit",
        },
      },
    ],
  },
  {
    path: "/districts",
    component: Body,
    children: [
      {
        path: "/districts",
        component: DistrictIndex,
        meta: {
          module: "district-View",
        },
      },
      {
        path: "/district/add",
        component: AddDistrict,
        meta: {
          module: "district-Add",
        },
      },
      {
        path: "/districts/edit/:id",
        component: EditDistrict,
        meta: {
          module: "district-Edit",
        },
      },
    ],
  },
  {
    path: "/district-officers",
    component: Body,
    children: [
      {
        path: "/district-officers",
        component: OfficerIndex,
        meta: {
          module: "districtOfficer-View",
        },
      },
      {
        path: "/district-officer/add",
        component: OfficerAdd,
        meta: {
          module: "districtOfficer-Add",
        },
      },
      {
        path: "/officers/edit/:id",
        component: OfficerEdit,
        meta: {
          module: "districtOfficer-Add",
        },
      },
    ],
  },
  {
    path: "/department",
    component: Body,
    children: [
      {
        path: "/department",
        component: DepartmentIndex,
        meta: {
          module: "departments-View",
        },
      },
      {
        path: "/department/add",
        component: DepartmentAdd,
        meta: {
          module: "departments-Add",
        },
      },
      {
        path: "/department/edit/:id",
        component: EditDepartment,
        meta: {
          module: "departments-Edit",
        },
      },
    ],
  },
  {
    path: "/department-officers",
    component: Body,
    children: [
      {
        path: "/department-officers",
        component: DepartmentOfficerIndex,
        meta: {
          module: "departmentOfficers-View",
        },
      },
      {
        path: "/department-officer/add",
        component: DepartmentOfficerAdd,
        meta: {
          module: "departmentOfficers-Add",
        },
      },
      {
        path: "/departmentOfficer/edit/:id",
        component: EditDepartmentOfficer,
        meta: {
          module: "departmentOfficers-Edit",
        },
      },
    ],
  },
  // {
  //   path: "/department-units",
  //   component: Body,
  //   children: [
  //     {
  //       path: "/department-units",
  //       component: DepartmentUnitIndex,
  //       meta: {
  //         module: "departmentUnit-View",
  //       },
  //     },
  //     {
  //       path: "/department-unit/add",
  //       component: DepartmentUnitAdd,
  //       meta: {
  //         module: "departmentUnit-Add",
  //       },
  //     },
  //     {
  //       path: "/departmentunit/edit/:id",
  //       component: EditDepartmentUnit,
  //       meta: {
  //         module: "departmentUnit-Edit",
  //       },
  //     },
  //   ],
  // },
  {
    path: "/complaints",
    component: Body,
    children: [
      {
        path: "/complaints",
        component: ComplaintIndex,
        meta: {
          module: "complaint-View",
        },
      },
      {
        path: "/complaint/add",
        component: ComplaintAdd,
        meta: {
          module: "complaint-Add",
        },
      },
      {
        path: "/complaints/re-appeal",
        component: ComplaintREAPPEAL,
      },
      {
        path: "/complaint/edit/:id",
        name: "complaint-detail",
        component: EditComplaint,
        meta: {
          module: "complaint-Edit",
        },
      },
    ],
  },
  {
    path: "/dynamic-form",
    component: Body,
    children: [
      {
        path: "/dynamic-form",
        component: DynamicForm,
        meta: {
          module: "sopForm-Add",
        },
      },
    ],
  },
  {
    path: "/sops",
    component: Body,
    children: [
      {
        path: "/sops",
        component: SOPSINDEX,
        meta: {
          module: "sop-View",
        },
      },
      {
        path: "/sops/add",
        component: SOPSAdd,
      },
    ],
  },
  {
    path: "/track-performance",
    component: Body,
    children: [
      {
        path: "/track-performance",
        component: TrackINDEX,
        meta: {
          module: "trackPerformance-View",
        },
      },
    ],
  },
  {
    path: "/notification",
    component: Body,
    children: [
      {
        path: "/notification",
        component: NotificationINDEX,
        meta: {
          module: "notification-View",
        },
      },
      {
        path: "/notification/add",
        component: NotificationAdd,
        meta: {
          module: "notification-Send",
        },
      },
    ],
  },
  {
    path: "/faq",
    component: Body,
    children: [
      {
        path: "/faq",
        component: FAQINDEX,
        meta: {
          module: "webContent",
        },
      },
      {
        path: "/faq/add",
        component: FAQAdd,
        meta: {
          module: "webContent",
        },
      },
      {
        path: "/faqs/edit/:id",
        component: FAQEDIT,
        meta: {
          module: "webContent",
        },
      },
    ],
  },
  {
    path: "/how-to",
    component: Body,
    children: [
      {
        path: "/how-to",
        component: HOWTOINDEX,
        meta: {
          module: "webContent",
        },
      },
      {
        path: "/how-to/add",
        component: HOWTOADD,
        meta: {
          module: "webContent",
        },
      },
      {
        path: "/howTo/edit/:id",
        component: HOWTOEdit,
        meta: {
          module: "webContent",
        },
      },
    ],
  },
  {
    path: "/country",
    component: Body,
    children: [
      {
        path: "/country",
        component: COUNTRYINDEX,
        meta: {
          module: "country-View",
        },
      },
      {
        path: "/country/add",
        component: COUNTRYADD,
        meta: {
          module: "country-Add",
        },
      },
      {
        path: "/country/edit/:id",
        component: COUNTRYEDIT,
        meta: {
          module: "country-Edit",
        },
      },
    ],
  },
  {
    path: "/province",
    component: Body,
    children: [
      {
        path: "/province",
        component: PROVINCEINDEX,
        meta: {
          module: "province-View",
        },
      },
      {
        path: "/province/add",
        component: PROVINCEADD,
        meta: {
          module: "province-Add",
        },
      },
      {
        path: "/province/edit/:id",
        component: PROVINCEEDIT,
        meta: {
          module: "province-Edit",
        },
      },
    ],
  },
  {
    path: "/question",
    component: Body,
    children: [
      {
        path: "/question/add",
        component: QUESTIONADD,
      },
      {
        path: "/form/edit/:id",
        component: PerviewForm,
      },
    ],
  },
  {
    path: "/feedback",
    component: Body,
    children: [
      {
        path: "/feedback",
        component: FEEDBACKINDEX,
        meta: {
          module: "feedback",
        },
      },

      {
        path: "/feedback/edit/:id",
        component: FEEDBACKEdit,
        meta: {
          module: "feedback",
        },
      },
    ],
  },
  {
    path: "/anonymous-feedback",
    component: Body,
    children: [
      {
        path: "/anonymous-feedback",
        component: AnonymousFeedback,
        meta: {
          module: "feedback",
        },
      },
      {
        path: "/anonymous-feedback/edit/:id",
        component: AnonymousView,
        meta: {
          module: "feedback",
        },
      },
    ],
  },
  {
    path: "/support",
    component: Body,
    children: [
      {
        path: "/support",
        component: SupportINDEX,
        meta: {
          module: "support",
        },
      },
      {
        path: "/support/edit/:id",
        component: SupportView,
        meta: {
          module: "support",
        },
      },
    ],
  },
  {
    path: "/app-users",
    component: Body,
    children: [
      {
        path: "/app-users",
        component: USERSINDEX,
        meta: {
          module: "appUsers-View",
        },
      },
      {
        path: "/app-users/add",
        component: USERSAdd,
        meta: {
          module: "appUsers-Add",
        },
      },
      {
        path: "/users/edit/:id",
        component: USERSEdit,
        meta: {
          module: "appUsers-Edit",
        },
      },
    ],
  },
  {
    path: "/active-complaints",
    component: Body,
    children: [
      {
        path: "/active-complaints",
        component: ACTIVECOMPLAINTS,
      },
    ],
  },

  {
    path: "/tehsil",
    component: Body,
    children: [
      {
        path: "/tehsil/add",
        component: TeshsilAdd,
      },
      {
        path: "/tehsil",
        component: TeshsilIndex,
      },
      {
        path: "/tehsil/edit/:id",
        component: TeshsilEdit,
      },
    ],
  },

  {
    path: "/area",
    component: Body,
    children: [
      {
        path: "/area/add",
        component: AreaAdd,
      },
      {
        path: "/area",
        component: AreaIndex,
      },
      {
        path: "/area/edit/:id",
        component: AreaEdit,
      },
    ],
  },

  {
    path: "/qualification",
    component: Body,
    children: [
      {
        path: "/qualification/add",
        component: QualificationAdd,
      },
      {
        path: "/qualification",
        component: QualificationIndex,
      },
      {
        path: "/qualification/edit/:id",
        component: QualificationEdit,
      },
    ],
  },

  {
    path: "/closed-complaints",
    component: Body,
    children: [
      {
        path: "/closed-complaints",
        component: CLOSEDCOMPLIANTS,
      },
    ],
  },

  {
    path: "/reappeal-complaints",
    component: Body,
    children: [
      {
        path: "/reappeal-complaints",
        component: REAPPEALCOMPLAINTS,
      },
    ],
  },

  {
    path: "/enforcement-appeals",
    component: Body,
    children: [
      {
        path: "/enforcement-appeals",
        component: REENFORCEMENTCOMPLAINTS,
      },
      {
        path: "/comments/add/:id",
        component: CHATENFORCEMENTINDEX,
      },
    ],
  },

  {
    path: "/web-pages",
    component: Body,
    children: [
      {
        path: "/web-pages",
        component: CONTENTINDEX,
        meta: {
          module: "webContent",
        },
      },
      {
        path: "/privacy-policy",
        component: PriacyPolicyIndex,
        meta: {
          module: "webContent",
        },
      },
      {
        path: "/term-and-condition",
        component: TermAndCondition,
        meta: {
          module: "webContent",
        },
      },
    ],
  },

  {
    path: "/ristricted-access",
    component: Body,
    children: [
      {
        path: "/ristricted-access",
        component: RistrictedAccessINDEX,
        meta: {
          module: "ra-View",
        },
      },
      {
        path: "/ristricted-access/add",
        component: RistrictedAccessAdd,
        meta: {
          module: "ra-Add",
        },
      },
      {
        path: "/ristricted-access/edit/:id",
        component: RistrictedAccessEdit,
        meta: {
          module: "ra-Edit",
        },
      },
    ],
  },

  {
    path: "/user-success-stories",
    component: Body,
    children: [
      {
        path: "/user-success-stories",
        component: StoryIndex,
      },
      {
        path: "/user-success-stories/add",
        component: StoryAdd,
      },
      {
        path: "/stories/edit/:id",
        component: StoryEdit,
      },
    ],
  },

  {
    path: "/news",
    component: Body,
    children: [
      {
        path: "/news",
        component: NewsIndex,
      },
      {
        path: "/news/add",
        component: NewsAdd,
      },
      {
        path: "/news/edit/:id",
        component: NewsEdit,
      },
    ],
  },

  {
    path: "/cmc-complaints",
    component: Body,
    children: [
      {
        path: "/cmc-complaints",
        component: CMCCOMPLAINTSINDEX,
      },
    ],
  },

  {
    path: "/eo-comlaint-detail",
    component: Body,
    children: [
      {
        path: "/eo-comlaint-detail/:id",
        component: EnforcementCompiantDetail,
      },
    ],
  },

  {
    path: "/search-and-reports",
    component: Body,
    children: [
      {
        path: "/search-and-reports",
        component: Reporting,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("token");
  if (!to.meta?.public && !isAuthenticated) {
    next({ name: "Login 1" });
  } else {
    next();
  }
});

// const permissions = localStorage.getItem("permissions");

// router.beforeEach((to, from, next) => {
//   const isAuthenticated = !!localStorage.getItem("token");

//   if (!to.meta?.public && !isAuthenticated && to.name !== "Login 1") {
//     next({ name: "Login 1" });
//   }else if(to.meta?.module=='forAll'){
//     next();
//   } else if (
//     isAuthenticated &&
//     !permissions.includes(to.meta?.module) &&
//     to.name !== "Dashboard"
//   ) {
//     next({ name: "Dashboard" });
//   } else {
//     next();
//   }
// });

export default router;
