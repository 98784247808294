import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    getAll({ dispatch, commit }, query) {
      return new Promise((resolve, reject) => {
        const queryString = query ? `?${Object.entries(query).map(([key, value]) => `${key}=${value}`).join('&')}` : '';
        const id = query.id;
        axiosInstance
          .get(`/notification/web/admin/${id}${queryString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    }, 
    getAllSendNotifications({ dispatch, commit }, query) {
      return new Promise((resolve, reject) => {
        const queryString = query ? `?${Object.entries(query).map(([key, value]) => `${key}=${value}`).join('&')}` : '';
        const id = query.id;
        axiosInstance
          .get(`/get-send-notifications${queryString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    }, 
    update({ dispatch, commit }, { data }) {
        return new Promise((resolve, reject) => {
          axiosInstance
            .post("/read-notification", data)
            .then((response) => {
              resolve(response.data);
            })
            .catch((e) => {
              reject(e.response.data);
            });
        });
      },
      store({ dispatch, commit }, { data }) {
        return new Promise((resolve, reject) => {
          axiosInstance
            .post("/store-notification", data)
            .then((response) => {
              resolve(response.data);
            })
            .catch((e) => {
              reject(e.response.data);
            });
        });
      },
  },
};
