<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="card">
          <div class="card-header card-no-border">
            <h5 class="card-title">Closed Complaints</h5>
          </div>
          <div class="card-body">
            <data-table :columns="columns" :module="module" :pagination="pagination" :searchBar="searchBar"
              :eoAdmins="eoAdmins" :items="items" :isAddEO="isAddEO" @addEo="addEo" :isChangePassword="changePassword"
              @deleted="fetch" @search="fetch" @clear="fetch" @nextPage="nextPage"></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      searchValue: "",
      viewable: true,
      columns: [
        {
          text: "Complainant Name",
          value: "user_profile",
          secondValue: "name",
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Date",
          value: "complaint_date",
        },
        {
          text: "Status",
          value: "complaintStatus",
        },
        {
          text: "Reference No.",
          value: "referenceNo",
        },
      ],
      items: [],
      eoAdmins: [],
      isAddEO: localStorage.getItem('role') == 'DC',
      module: "complaint",
      loading: false,
      searchBar: true,
      pagination: true,
    };
  },
  mounted() {
    this.fetch();
    this.fetchEO();
  },
  methods: {
    fetch(search = "") {
      this.loading = true;
      const type = "closedComplaints";
      this.$store
        .dispatch("complaint/complaintTypes", { search, type })
        .then((response) => {
          this.items = response.data;
          this.items.data.forEach((item) => {
            if (item.complaintStatus == "Close") {
              item.complaintStatus = "Closed";
            }
          });
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchEO() {
      this.loading = true;
      this.$store
        .dispatch("complaint/districtEo")
        .then((response) => {
          this.eoAdmins = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    addEo(data) {
      this.loading = true;
      this.errors = [];
      this.$store
        .dispatch("complaint/storeEO", { data })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly added.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          window.location.reload();
          this.errors = [];
          this.fetch();
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });
    },
    nextPage(url, search = "") {
      this.loading = true;
      axiosInstance.get(`${url}&paginate=true&search=${search}`).then((response) => {
        this.items = response.data.data;
        this.items.data.forEach((item) => {
          if (item.complaintStatus == "Close") {
            item.complaintStatus = "Closed";
          }
        });
        this.loading = false;
      });
    },
  },
};
</script>